<template>
  <v-container>
    <!-- Display the orders -->
    <v-data-table
      :headers="headers"
      :items="orders"
      item-value="orderId"
      class="elevation-1"
      :items-per-page="itemsPerPage"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Liste des commandes</v-toolbar-title>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon class="icon-consult" @click="goToDetail(item.orderId)">mdi-eye</v-icon>
      </template>

      <template v-slot:item.orderState="{ item }">
        <div class="status-container">
          <span :class="{'status-dot-orange': item.orderState === 'EN_COURS', 'status-dot-green': item.orderState === 'EXPEDIE'}"></span>
          <v-select
            v-model="item.orderState"
            :items="statuses"
            label="Statut de commande"
            class="status-select"
            @change="() => updateOrder(item)" 
          ></v-select>
        </div>
      </template>
    </v-data-table>

    <!-- Pagination component -->
    <div class="pagination-container">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        :total-visible="7"
        @input="handlePageChange"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios';
import Constant from "@/utils/constants";

export default {
  data() {
    return {
      headers: [
        { text: "Nom", value: "clientName" },
        { text: "Prenom ", value: "" },
        { text: "Adresse de livraison", value: "orderAddress" },
        { text: "Nombre d'articles ", value: "" },
        { text: "Email Client", value: "" },
        { text: "Statut", value: "orderState" },
        { text: "Consulter", value: "actions", sortable: false }
      ],
      orders: [],
      currentPage: 1,
      itemsPerPage: 10, // Items per page
      totalPages: 0,
      statuses: ['EN_COURS', 'EXPEDIE'], // Available statuses
      snackbar: false,
      snackbarMessage: '',
      snackbarColor: 'success',
    };
  },
  async created() {
    await this.fetchOrders();
  },
  methods: {
    async fetchOrders() {
      const userIdtest = localStorage.getItem('ownerId');
      try {
        const response = await axios.get(Constant.QORANI + `library/library/getByOwnerId/${userIdtest}`);
        this.library = response.data;

        const ordersResponse = await axios.post(
          Constant.QORANI + `library/order/getListOrder`,
          {
            id: this.library.id,
            isLibrary: true,
            pageNumber: this.currentPage - 1,
          }
        );

        this.orders = ordersResponse.data.orderResponseList;
        this.totalPages = ordersResponse.data.totalPageNumber;

      } catch (error) {
        console.error('There was an error fetching the orders:', error);
      }
    },
    async updateOrder(order) {
  // Log the order to debug
  console.log('Updating order:', order);

  const numericOrderId = order?.orderId; // Use optional chaining
  const orderStatus = order?.orderState; // Use optional chaining

  if (!numericOrderId || !orderStatus) {
    console.error('Invalid order ID or order status.', { numericOrderId, orderStatus });
    return;
  }

  try {
    const url = `${Constant.QORANI}library/order/editOrderStatus/${numericOrderId}/staus/${orderStatus}`;
    const response = await axios.get(url);
    console.log('Order updated successfully:', response.data);

    // Show success notification
    this.snackbarMessage = 'Statut de la commande mis à jour avec succès!';
    this.snackbarColor = 'success';
    this.snackbar = true;

    this.fetchOrders();
  } catch (error) {
    console.error('Error updating the order status:', error);
    // Show error notification
    this.snackbarMessage = 'Erreur lors de la mise à jour du statut de la commande.';
    this.snackbarColor = 'error';
    this.snackbar = true;
  }
},

    handlePageChange(page) {
      this.currentPage = page;
      this.fetchOrders();
    },

    // Navigate to DetailOrder 
    goToDetail(orderId) {
      this.$router.push({ name: 'DetailOrder', params: { orderId } });
    },
  }
};
</script>


<style scoped>
.status-container {
  display: flex;
  align-items: center;
}

.status-dot-orange {
  width: 10px;
  height: 10px;
  background-color: orange;
  border-radius: 50%;
  margin-right: 5px;
}

.status-dot-green {
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  margin-right: 5px;
}


.v-icon {
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}

.icon-edit:hover {
  color: #1565c0; /* Darker blue on hover */
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total-pages-info {
  font-size: 14px;
  margin-left: auto;
  color: gray;
}
</style>
